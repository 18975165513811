import React, { useState, useRef, useEffect } from "react"
import Taps from "./Taps"
import Links from "./Links"
import HeaderMessege from "./HeaderMessage"
import windowDimensions from "../../util/windowDimensions"
import { TapTag, StyledHeader } from "../globalStyles"

import "./header.css"

const Header = ({ children }) => {
  const { width, height } = windowDimensions()
  const refresh = () => {
    window.location.reload()
  }

  return (
    <StyledHeader>
      <div className="full-view">
        <div className="logo">
          <svg
            width="60"
            height="60"
            viewBox="0 0 372 318"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => refresh()}
          >
            <g clip-path="url(#clip0)">
              <line
                x1="35.9999"
                y1="41.9757"
                x2="36.9999"
                y2="247.976"
                stroke="#FFA500"
                stroke-width="10"
              />
              <line
                x1="65.9999"
                y1="41.9757"
                x2="66.9999"
                y2="247.976"
                stroke="#FFA500"
                stroke-width="10"
              />
              <path
                d="M257.125 247H229.984V152.359H126.766V247H99.7656V42.25H126.766V130.281H229.984V42.25H257.125V247Z"
                fill="#FFA500"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="372" height="318" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <Taps />
      </div>

      <div style={width < 800 ? { marginTop: "40px" } : { textAlign: "left" }}>
        <HeaderMessege />

        <Links />
      </div>

      <div
        style={{
          textAlign: "end",
          position: "absolute",
          right: "5px",
          top: "17vh",
        }}
      >
        <h4
          style={{
            writingMode: "vertical-rl",
            textOrientation: "upright",
            color: "orange",
          }}
        >
          HALDOLAAY@GMAIL.COM
        </h4>
        <div
          style={{
            borderRight: "thick solid orange",
            height: "200px",
            marginRight: "27px",
          }}
        ></div>
      </div>
    </StyledHeader>
  )
}

export default Header
