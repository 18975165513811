import Styled from "styled-components"
import { Link } from "gatsby"
import { keyframes } from "styled-components"

const fadeAnimation = keyframes`
 0% { opacity: 0 ; margin:100px; }
 100% { opacity: 1; margin: 1px}
`
const fadeOut = keyframes`
0% { opacity: 0  ;left:10% }
100% { opacity: 1; left: 0%}
`

const ProjectAnimation = keyframes`
0%{width:250px; }
100%{width: 450px;
}
`
const StyledTaps = Styled.div`
margin-right:100px;
width: 100%;
display: flex;
justify-content: flex-end;
font-size: 20px;
color:white;
`
const FadeText = Styled.div`
font-size: 3vmax;
color: white;
margin-left: 100px;
padding: 0;
border: 0;
outline: 0;
// margin-bottom:5px;
text-align:left;
h1{
  text-decoration: underline;
  text-decoration-color: orange;
  animation-name: slidein;
  animation-duration: 1s;
  animation-iteration-count: 1;
}
h4{
  color: #8892B0;
  animation-name: slidein;
  animation-duration: 2s;
  animation-iteration-count: 1;
  // animation-delay:1s;
}
h6{
  animation-name: slidein;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: ease

}
p{
  color: #8892B0;
  animation-name: slideright;
  animation-duration: 3s;
  animation-iteration-count: 1;
}

`

const StyledHeader = Styled.div`

.logo{
  animation-name: slideleft;
  animation-duration: 3s;
  animation-iteration-count: 1;  
}
`
const StyledLayout = Styled.div`

background:#040017;
background-repeat: no-repeat;
background-size: cover;
background-attachment: fixed;
font-family: 'Anton', sans-serif;
color: white;
text-align: center;
margin:0;
padding:0;
#main-content{
  overflow-x:hidden;
 margin:0;
 padding:0;
 height:100%;
 width:100%;
}
`

const TapTag = Styled(Link)`
font-size:1.2em;
color: white;
font-weight: bold;
padding: 2px;
margin: 5px;
text-decoration: underline 0.15em rgba(255, 255, 255, 0);
transition: text-decoration-color 300ms;
// animation-name: ${fadeOut};
animation-name: slideright;
animation-duration: ${props => props.duration};
animation-iteration-count:1;
:hover{

  text-decoration-color: orange
}

`

const StyledButton = Styled(Link)`
margin: 20px !important;
padding: 10px !important;
background: inherit;
color: white;
font-size: 2em;
font-weight: bold;
border: 3px solid white;
text-decoration: none;
:hover{
  cursor: pointer;
  filter: drop-shadow(8px 8px 10px orange);
}
`

const SecretDiv = Styled.div`
:button{
  display:none;
}
:hover{
  :button{
    display: relative;
    text-align: center;

  }
}

`
const Wrapper = Styled.div`

display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
flex-direction: column;
margin-top: 100px;
margin-bottom: 200px
font-family : "Poppins", san-serif;
// border: 3px solid orange;

`
const Container = Styled.div`
position:relative;
width:80%;
height:500px;
 display: flex;
 justify-content: center;
 margin-top:10px;
 margin-bottom:10px;
`
const StyledForm = Styled.form`
overflow-x: hidden;
display: flex;
flex-wrap: wrap;
flex-direction: column;
justify-content: center;
align-items: center;
font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
border-color: #060c21;
button{
  font-size: 15px;
  display: block;
  width: 20em;
  height: 5em;
  background: #060c21;
  color: white;
  :hover{
    opacity: 0.8;
    cursor: pointer;
  }
}

label {
  display: block;
  text-align: center !important;
}
textarea{
  height: 150px !important;
  width: 300px !important;

}
input[type=text], input[type=email], textarea {
  width: 300px;
  padding: 20px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

`
const FooterLinksDiv = Styled.div`

position: absolute;
right: 5px;
margin: 0;
padding: 0;
`

const StyledCard = Styled.div`
display: flex;
justify-content: center;
flex-direction: column;
border: 1px solid black;
width: 300px;
height: 300px;
margin: 5px;
padding: 5px;
background:#0e000d;
color: white;
border-radius: 10%;
word-break:break-all;
transition:  ease 0.5s;
position:relative;
:hover{
margin: -10px;
color: orange;
filter: drop-shadow(8px 8px 10px orange);
}

`
export {
  StyledHeader,
  StyledLayout,
  StyledTaps,
  StyledButton,
  StyledForm,
  StyledCard,
  TapTag,
  Wrapper,
  SecretDiv,
  fadeAnimation,
  FadeText,
  Container,
  FooterLinksDiv,
}
