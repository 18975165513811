import React, { useState, useEffect } from "react"
import { FooterLinksDiv } from "../globalStyles"
import FooterLinks from "./footerLinks"
import windowDimensions from "../../util/windowDimensions"
import LinkButton from "../../util/LinkButton"
const Footer = () => {
  const { height, width } = windowDimensions()

  return (
    <footer
      style={{
        height: "250px",
        padding: "40px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {width > 1300 && (
        <div
          style={{
            position: "absolute",
            float: "left",
            width: "300px",
            fontWeight: "bold",
          }}
        >
          <FooterLinks />
        </div>
      )}

      <div style={{ marginBottom: "100px" }}>
        {width > 800 && <FooterLinksDiv></FooterLinksDiv>}
        <br />
      </div>

      <span>©{new Date().getFullYear()} Hamzah Aldolaay</span>
      {width > 1300 ? (
        <span style={{ position: "absolute", right: "20px" }}>
          <LinkButton
            text="Old Site"
            link="https://dazzling-goldberg-c5a522.netlify.app"
          />
        </span>
      ) : (
        <div>
          <br />
          <br />
          <LinkButton
            text="Old Site"
            link="https://dazzling-goldberg-c5a522.netlify.app"
          />
        </div>
      )}
    </footer>
  )
}
export default Footer

{
  /* <div>
  <h1>Interested in doing a project together?</h1>
  <br />
  <a
    href="mailto: haldolaay@gmail.com"
    style={{
      textDecoration: "none",
      background: "inherit",
      color: "white",
      border: "2px solid white",
      fontWeight: "bold",
      padding: "5px",
      margin: "15px",
    }}
  >
    Contact Me{" "}
  </a>
</div> */
}
