import React from "react"
import windowDimensions from "./windowDimensions"
const HeaderLine = ({ text }) => {
  const { width, height } = windowDimensions()
  const text_h2 = {
    width: "50%",
    textAlign: "left",
    borderBottom: "1px solid orange",
    lineHeight: "0.1em",
    margin: "100px 100px 20px",
  }
  const text_span = {
    background: "#040017",
    padding: "0 10px",
  }

  return (
    <div
      style={{
        textAlign: "left",
      }}
    >
      <h2
        style={
          width > 800
            ? text_h2
            : {
                textAlign: "center",
                width: "100%",
                textDecoration: "underline",
                textDecorationColor: "orange",
              }
        }
      >
        <span style={width > 800 ? text_span : { textAlign: "center" }}>
          {text}
        </span>
      </h2>
    </div>
  )
}
export default HeaderLine
