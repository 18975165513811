import React, { Fragment } from "react"
import Header from "./Header/header"
import Footer from "./Footer/footer"
import { StyledLayout } from "./globalStyles"
import FixedHeader from "./Header/fixedHeader"
const Layout = ({ children }) => {
  return (
    <StyledLayout>
      <FixedHeader />
      <Header />
      <main id="main-content">{children}</main>
      <Footer />
    </StyledLayout>
  )
}

export default Layout
