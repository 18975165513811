import React from "react"
import "./customButton.css"
const CustomButton = ({ text, setValue, projectList, operator }) => {
  const buttonStyles = {}

  return (
    <button
      className="button-style"
      onClick={() => {
        operator == "+" ? setValue(projectList + 4) : setValue(4)
      }}
    >
      {text}
    </button>
  )
}
export default CustomButton
