import React, { useState, useEffect } from "react"
import { StyledCard, StyledButton } from "./globalStyles"
import CustomButton from "../util/customButton"
import HeaderLine from "../util/headerLine"
export default function ProjectPage() {
  const [data, setData] = useState("")
  const [projectList, setProjectList] = useState(4)
  const [projectCount, setProjectCount] = useState(100)
  useEffect(() => {
    fetch("https://api.github.com/users/Haldolaay/repos")
      .then(response => response.json())
      .then(response => {
        setProjectCount(response.length)
        setData(response)
      })
  }, [])
  return (
    <>
      <HeaderLine text="Other Projects" />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: "100px",
        }}
      >
        {data &&
          data.slice(0, projectList).map((item, index) => (
            <StyledCard>
              <h2
                style={{
                  margin: "50px",
                  fontFamily: "Arial, Helvetica, sans-serif",
                  padding: "5px",
                }}
              >
                <strong>{item.name}</strong>
              </h2>
              <span>
                <strong>
                  {" "}
                  {item.language ? `  Main Language : ${item.language}` : ""}
                </strong>
              </span>
              <StyledButton
                style={{
                  color: "white",
                  borderColor: "white",
                  background: "inherit",
                }}
                href={item.clone_url}
                target="_blank"
              >
                <strong>check Repo</strong>
              </StyledButton>
            </StyledCard>
          ))}
      </div>
      {projectList < projectCount ? (
        <CustomButton
          text="Show More!"
          setValue={setProjectList}
          projectList={projectList}
          operator="+"
        />
      ) : (
        <CustomButton
          text="Hide!"
          setValue={setProjectList}
          projectList={projectList}
          operator="-"
        />
      )}
    </>
  )
}
