import React, { useState, useEffect, useRef } from "react"
import html from "../../images/html.svg"
import css3 from "../../images/css.svg"
import cSharp from "../../images/csharp.svg"
import cPlus from "../../images/cplusplus.svg"
import mssql from "../../images/mssql.svg"
import java from "../../images/java.svg"
import javascript from "../../images/javascript.svg"
import nodejs from "../../images/node.svg"
import react from "../../images/react.svg"
import redux from "../../images/redux.svg"
import mysql from "../../images/mysql.svg"
import bootstrap from "../../images/bootstrap.svg"
import python from "../../images/python.svg"
import firebase from "../../images/firebase.svg"
import git from "../../images/git.svg"
import d3 from "../../images/d3.svg"
import materialui from "../../images/materialui.svg"
import mongodb from "../../images/mongodb.svg"
import UseOnScreen from "../../util/useOnScreen"
import "./about.css"
const AboutPage = () => {
  const [count, setCount] = useState(0)
  const [count2, setCount2] = useState(0)
  const ref = useRef()
  const ref2 = useRef()
  const onScreen = UseOnScreen(ref, "-10px")
  const onScreen2 = UseOnScreen(ref2, "-10px")
  useEffect(() => {
    if (onScreen) {
      setCount(1)
    }
    if (onScreen2) {
      setCount2(1)
    }
  }, [onScreen, onScreen2])

  return (
    <div id="about">
      <h2 style={{ color: "white !important" }}>Main Skills:</h2>
      <div className={count == 1 ? "main-skill" : ""} ref={ref}>
        <div
          className="skill"
          style={count == 1 ? { opacity: 1 } : { opacity: 0 }}
        >
          <img
            alt="html"
            src={html}
            style={{ width: "100px", height: "100px" }}
          />
          <p>HTML</p>
        </div>
        <div className="skill">
          <img
            alt="css"
            src={css3}
            style={{ width: "100px", height: "100px" }}
          />
          <p>CSS3</p>
        </div>
        <div className="skill">
          <img
            alt="javascript"
            src={javascript}
            style={{ width: "100px", height: "100px" }}
          />
          <p>JavaScript</p>
        </div>

        <div className="skill">
          <img
            alt="react"
            src={react}
            style={{ width: "100px", height: "100px" }}
          />
          <p>React</p>
        </div>
        <div className="skill">
          <img
            alt="redux"
            src={redux}
            style={{ width: "100px", height: "100px" }}
          />
          <p>Redux</p>
        </div>
        <div className="skill">
          <img
            alt="node"
            src={nodejs}
            style={{ width: "100px", height: "100px" }}
          />
          <p>Nodejs</p>
        </div>
        <div className="skill">
          <img
            alt="mongo"
            src={mongodb}
            style={{ width: "100px", height: "100px" }}
          />
          <p>MongoDb</p>
        </div>
        <div className="skill">
          <img
            alt="git"
            src={git}
            style={{ width: "100px", height: "100px" }}
          />
          <p>Git</p>
        </div>
      </div>

      <h2 style={{ color: "white !important" }}>
        I also have experience with:{" "}
      </h2>
      <div
        className={count2 == 1 ? "main-skill" : ""}
        ref={ref2}
        style={count2 == 1 ? { opacity: 1 } : { opacity: 0 }}
      >
        <div className="skill">
          <img
            alt="cplusplus"
            src={cPlus}
            style={{ width: "100px", height: "100px" }}
          />
          <p>C++</p>
        </div>
        <div className="skill">
          <img
            alt="csharp"
            src={cSharp}
            style={{ width: "100px", height: "100px" }}
          />
          <p>C#</p>
        </div>
        <div className="skill">
          <img
            alt="java"
            src={java}
            style={{ width: "100px", height: "100px" }}
          />
          <p>java</p>
        </div>
        <div className="skill">
          <img
            alt="python"
            src={python}
            style={{ width: "100px", height: "100px" }}
          />
          <p>Python</p>
        </div>
        <div className="skill">
          <img
            alt="mssql"
            src={mssql}
            style={{ width: "100px", height: "100px" }}
          />
          <p>Mssql</p>
        </div>

        <div className="skill">
          <img
            alt="mysql"
            src={mysql}
            style={{ width: "100px", height: "100px" }}
          />
          <p>MySql</p>
        </div>
        <div className="skill">
          <img
            alt="fire"
            src={firebase}
            style={{ width: "100px", height: "100px" }}
          />
          <p>FireBase</p>
        </div>

        <div className="skill">
          <img alt="d3" src={d3} style={{ width: "100px", height: "100px" }} />
          <p>D3</p>
        </div>
      </div>
    </div>
  )
}
export default AboutPage
