import React from "react"
import { FaReact } from "react-icons/fa"
import { SiRedux } from "react-icons/si"
import { DiJavascript } from "react-icons/di"
import { DiCss3 } from "react-icons/di"
import { DiJava } from "react-icons/di"
import { SiCsharp } from "react-icons/si"
import { AiOutlineConsoleSql } from "react-icons/ai"
import { SiNodeDotJs } from "react-icons/si"
import { IconContext } from "react-icons"
export default function FooterLinks(props) {
  return (
    <IconContext.Provider
      value={{
        style: {
          fontSize: "30px",
          color: "white",
          padding: "10px",
          margin: "10px",
        },
      }}
    >
      <div>
        <FaReact />
        <SiRedux />
        <DiJavascript />
        <DiCss3 />
        <br />
        <SiCsharp />
        <DiJava />
        <AiOutlineConsoleSql />
        <SiNodeDotJs />
      </div>
    </IconContext.Provider>
  )
}
