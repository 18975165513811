import React from "react"
import { FaFacebookF } from "react-icons/fa"
import { FiGithub } from "react-icons/fi"
import { IconContext } from "react-icons"
import { FiTwitter } from "react-icons/fi"
import { FiLinkedin } from "react-icons/fi"
import { Link } from "gatsby"

import "./header.css"
export default function Links(props) {
  return (
    <IconContext.Provider
      value={{
        style: {
          width: "30px",
          fontSize: "30px",
          color: "orange",
          padding: "10px",
          margin: "10px",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <div>
        <div style={{ width: "30px" }}>
          <div className="links">
            <Link to="https://www.facebook.com/hamza.aldolay" target="_blank">
              <FaFacebookF />
              {props.face}
            </Link>
          </div>
          <div className="links">
            <Link to="https://github.com/Haldolaay" target="_blank">
              <FiGithub />
              {props.gethub}
            </Link>
          </div>
          <div className="links">
            <Link to="https://twitter.com/Haldolaay1" target="_blank">
              <FiTwitter />
              {props.twitter}
            </Link>
          </div>
          <div className="links">
            <Link to="https://www.linkedin.com/in/haldolaay" target="_blank">
              <FiLinkedin />
              {props.Linkedin}
            </Link>
          </div>
          <div
            style={{
              borderLeft: "thick solid orange",
              height: "200px",
              marginLeft: "30px",
            }}
          ></div>
        </div>
      </div>
    </IconContext.Provider>
  )
}
