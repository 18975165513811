import React, { Fragment } from "react"
import Project from "./Project"
import HeaderLine from "../../util/headerLine"
import { Wrapper } from "../globalStyles"
import image from "../../images/capture.png"
import image2 from "../../images/capture2.png"
import image3 from "../../images/capture3.png"
import image4 from "../../images/capture4.png"
import image5 from "../../images/capture5.png"
const Projects = () => {
  return (
    <Fragment>
      <HeaderLine text="Some Projects I built" />

      <Wrapper>
        <Project
          imgClass="img1"
          text="Card Directory"
          explain="Search for Cards"
          image={image}
          textColor="white"
          bColor="orange"
          site="https://jolly-shockley-5a15e4.netlify.app"
          lang="Reactjs      Redux    materialUI"
          num={1}
          explain="SPA for YoGiOh cards, you can search, filter and pick the cards you want and build your own deck! you can also check for the price of each card or the deck!"
        />

        <Project
          imgClass="img2"
          text="Alive"
          explain="log in / checkout"
          image={image2}
          textColor="white"
          bColor="orange"
          site="https://peaceful-varahamihira-e755f4.netlify.app"
          lang="Reactjs   Redux    materielizeCss FireBase"
          num={2}
          explain="website to check on your friends and what they are doing, you can log in and share what you've been up to during the times you were away"
        />
        <Project
          imgClass="img3"
          text="WellsForgot"
          explain="bank app"
          image={image3}
          textColor="white"
          bColor="orange"
          site="https://wellsforgot.herokuapp.com/"
          lang="Nodejs    Expressjs    MongoDb  Ejs  "
          num={3}
          explain="website for a fake bank, it has a landing page - login - signup - and checking account - basic CRUD app "
        />
        <Project
          imgClass="img4"
          explain="Search for Events"
          text="findEvent"
          image={image4}
          textColor="white"
          bColor="orange"
          site="https://keen-wescoff-5b4f1d.netlify.app/"
          lang="ArcGIS  JavaScript oldschool font "
          num={4}
          explain="search for events in your area, the map will automatically update depending on what you're looking at"
        />
        <Project
          imgClass="img5"
          text="International Travel Agency"
          explain="get quota/ subscribe"
          image={image5}
          textColor="white"
          bColor="orange"
          site="https://lit-beyond-07510.herokuapp.com"
          lang="Reactjs   Bootstrap MongoDb"
          num={5}
          explain="Book flights, subscribe to our mail service and get great offers - full stack application website for a travel agency"
        />
      </Wrapper>
    </Fragment>
  )
}
export default Projects
