import React from "react"
const LinkButton = props => {
  return (
    <a
      href={props.link}
      style={{
        textDecoration: "none",
        background: "inherit",
        color: "white",
        border: "2px solid white",
        fontWeight: "bold",
        padding: "5px",
        marginTop: "200px",
        animationName: "slideright",
        animationDuration: "3.5s",
        animationIterationCount: "1",
      }}
    >
      {props.text}
    </a>
  )
}

export default LinkButton
