import React, { useState, useEffect, useRef } from "react"
import "./project.css"
import UseOnScreen from "../../util/useOnScreen"
export const LeftSide = props => {
  const [count, setCount] = useState(0)
  const ref = useRef()
  const onScreen = UseOnScreen(ref, "-10px")
  useEffect(() => {
    if (onScreen) {
      setCount(1)
    }
  }, [onScreen])

  return (
    <>
      {" "}
      <div
        className={count == 1 ? "left-side" : ""}
        style={count == 1 ? { opacity: 1 } : { opacity: 0 }}
      >
        <div>
          <h4 style={{ color: "#6473ff" }}>Project Name:</h4>
          <h2>
            <a
              href={props.site}
              style={{ textDecoration: "none", color: "white" }}
              target="_blank"
            >
              {props.text}
            </a>
          </h2>
        </div>
        <div
          className="explain-left"
          style={count == 1 ? { opacity: 1 } : { opacity: 0 }}
        >
          <p style={{ margin: "3%", fontSize: "1.5em" }}>{props.explain}</p>
        </div>
        <div>
          <strong>{props.lang}</strong>
        </div>
      </div>
      <div
        ref={ref}
        className={`${props.imgClass} ${count == 1 ? "effect-left" : ""}`}
        style={count == 1 ? { opacity: 1 } : { opacity: 0 }}
      ></div>
    </>
  )
}
