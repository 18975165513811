import React from "react"
import { TapTag, StyledTaps } from "../globalStyles"

const Taps = ({ children }) => {
  return (
    <StyledTaps>
      <TapTag to="#main-content" duration={1}>
        Projects
      </TapTag>
      <TapTag to="#about" duration={3}>
        About
      </TapTag>
      <TapTag to="#contact-info" duration={4}>
        Contact
      </TapTag>
      <TapTag to="/resume" duration={2}>
        <div>Resume</div>
      </TapTag>
    </StyledTaps>
  )
}
export default Taps
