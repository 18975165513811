import React from "react"
import Taps from "./Taps"
import { TapTag } from "../globalStyles"
import { slide as Menu } from "react-burger-menu"
import "./header.css"
class FixedHeader extends React.Component {
  state = { isHide: false, menuOpen: false }
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen })
  }
  closeMenu() {
    this.setState({ menuOpen: false })
  }
  toggleMenu() {
    this.setState(state => ({ menuOpen: !state.menuOpen }))
  }
  hideBar = () => {
    const { isHide } = this.state
    if (typeof window !== `undefined`) {
      window.scrollY > this.prev
        ? !isHide && this.setState({ isHide: true })
        : isHide && this.setState({ isHide: false })
      this.prev = window.scrollY
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.hideBar)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.hideBar)
  }

  render() {
    const refresh = () => {
      if (typeof window !== `undefined`) window.location.reload()
    }
    const classHide = this.state.isHide ? "hide" : ""
    return (
      <div
        className={`full-view fixed-header ${classHide}`}
        style={
          typeof window !== `undefined` && window.scrollY > 400
            ? null
            : { display: "none" }
        }
      >
        <div className="logo">
          <svg
            width="60"
            height="60"
            viewBox="0 0 372 318"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => refresh()}
          >
            <g clip-path="url(#clip0)">
              <line
                x1="35.9999"
                y1="41.9757"
                x2="36.9999"
                y2="247.976"
                stroke="#FFA500"
                stroke-width="10"
              />
              <line
                x1="65.9999"
                y1="41.9757"
                x2="66.9999"
                y2="247.976"
                stroke="#FFA500"
                stroke-width="10"
              />
              <path
                d="M257.125 247H229.984V152.359H126.766V247H99.7656V42.25H126.766V130.281H229.984V42.25H257.125V247Z"
                fill="#FFA500"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="372" height="318" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <Taps />
        <div className="mobile-view">
          <Menu
            styles={styles}
            right
            isOpen={this.state.menuOpen}
            onStateChange={state => this.handleStateChange(state)}
          >
            <TapTag to="#main-content" onClick={() => this.closeMenu()}>
              Projects
            </TapTag>
            <TapTag to="/resume" onClick={() => this.closeMenu()}>
              Resume
            </TapTag>
            <TapTag to="#about" onClick={() => this.closeMenu()}>
              About
            </TapTag>
            <TapTag to="#contact-info" onClick={() => this.closeMenu()}>
              Contact
            </TapTag>
          </Menu>
        </div>
      </div>
    )
  }
}
var styles = {
  // burger button style
  bmBurgerButton: {
    position: "absolute",
    width: "36px",
    height: "30px",
    right: "3px",
    top: "5px",
  },
  bmBurgerBars: {
    background: "white",
  },
  bmBurgerBarsHover: {
    background: `orange`,
  },
  bmCrossButton: {
    height: "14px",
    width: "14px",
  },
  bmCross: {
    background: "white",
  },
  // menu style
  bmMenuWrap: {
    // background: `#040017`,
    position: "fixed",
    top: "0",
    bottom: "0",
    height: "50%",
    width: "50%",
    margin: "0px",
    padding: "0px",
  },
  bmMenu: {
    // backgroundImage: `#040017`,
    // backgroundPosition: "center",
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "cover",
    padding: "0px",
    margin: "0px",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "white",
  },
  bmItemList: {
    color: "white",
    paddingTop: "4em",
  },
  bmItem: {
    display: "inline-block",
    width: "70%",
  },
  bmOverlay: {
    // background: `#040017`,
  },
}
export default FixedHeader
