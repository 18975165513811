import React, { useState, useEffect, useRef } from "react"
import { FadeText } from "../globalStyles"
import UseOnScreen from "../../util/useOnScreen"
import LinkButton from "../../util/LinkButton"
const HeaderMessege = () => {
  const [count, setCount] = useState(0)
  const ref = useRef()
  const onScreen = UseOnScreen(ref, "-10px")
  useEffect(() => {
    if (onScreen) {
      setCount(1)
    }
  }, [onScreen])
  return (
    <>
      <FadeText ref={ref}>
        <div style={count == 1 ? { opacity: 1 } : { opacity: 0 }}>
          <h1 style={{ fontWeight: "bolder", marginBottom: 0 }}>
            {" "}
            Hamzah Aldolaay.
          </h1>
          <h4 style={{ margin: 0 }}>Software Engineer in California.</h4>
          <h6
            style={{
              fontWeight: "lighter",
              margin: 0,
              marginBottom: "10px",
              fontSize: "1vw",
            }}
          >
            {" "}
            FRONTEND | BACKEND | DATABASES | DEVOPS | TESTING
          </h6>
          <p className="text-explination">
            I love to work with inspiring companies and people.
          </p>
          <LinkButton text="Get In Touch!" link="mailto: haldolaay@gmail.com" />
        </div>
      </FadeText>
    </>
  )
}
export default HeaderMessege
