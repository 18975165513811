import React from "react"
import Layout from "../components/layout"
import Projects from "../components/Projects/projects.js"
import ContactForm from "../components/ContactForm"
import ProjectList from "../components/projectList"
import HeaderLine from "../util/headerLine"
import About from "../components/About/about"

const IndexPage = () => (
  <Layout>
    <Projects />
    <div>
      <ProjectList />
    </div>
    <HeaderLine text="About" />
    <About />
    <HeaderLine text="Contact information" />
    <ContactForm>
      <h1>Interested in doing a project together?</h1>

      <h1>You can Email me on </h1>
      <h4>Haldolaay@gmail.com</h4>
      <h4>or leave a message</h4>
    </ContactForm>
  </Layout>
)

export default IndexPage
