import React from "react"
import { StyledForm } from "./globalStyles"
import { StyledButton } from "../components/globalStyles"

const ContactForm = props => {
  return (
    <StyledForm
      // action="mailto: haldolaay@gmail.com"
      id="contact-info"
      name="contact"
      method="POST"
      data-netlify="true"
      netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="contact" required />
      {props.children}
      <div>
        <label htmlFor="name">Name</label>
        <input type="text" name="name" />
      </div>
      <div>
        <label htmlFor="exampleInputEmail1">Email </label>
        <input
          type="email"
          name="email"
          aria-describedby="emailHelp"
          required
        />
      </div>
      <div>
        <label htmlFor="message">Message</label>
        <textarea rows="5" name="message"></textarea>
      </div>
      <StyledButton type="submit">
        <h2>Submit</h2>
      </StyledButton>
    </StyledForm>
  )
}
export default ContactForm
