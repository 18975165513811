import React from "react"
import { Container } from "../globalStyles"
import "./project.css"
import { RightSide } from "./rightSide"
import { LeftSide } from "./leftSide"
const Project = props => {
  return (
    <Container>
      {props.num % 2 != 0 ? (
        <>
          <RightSide
            imgClass={props.imgClass}
            text={props.text}
            lang={props.lang}
            explain={props.explain}
            site={props.site}
          />{" "}
        </>
      ) : (
        <>
          <LeftSide
            imgClass={props.imgClass}
            text={props.text}
            lang={props.lang}
            explain={props.explain}
            site={props.site}
          />{" "}
        </>
      )}
    </Container>
  )
}
export default Project
